import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FaBuysellads } from "react-icons/fa6";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IAd } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function AdList() {
  useTitle("Ad List");

  const [ads, setAds] = useState<IAd[]>([]);

  const [deleteAd, setDeleteAd] = useState<IAd | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [listRequest, listLoading] = useRequest(true);
  const [deleteRequest, deleteLoading] = useRequest();

  const columns: TableProps<IAd>["columns"] = useMemo(
    () => [
      {
        key: "ad_name",
        title: "Name",
        dataIndex: "ad_name",
      },
      {
        key: "campaign",
        title: "Campaign",
        render: (_, a) => <span>{a.campaign.campaign_name}</span>,
      },
      {
        key: "product",
        title: "Product",
        render: (_, a) => <span>{a.product?.product_name}</span>,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, a) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteAd(a)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/home/ads/${a.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "campaigns/api/ads/webremove/",
      () => {
        setAds((old) => old.filter((b) => b.id !== deleteAd?.id));
        setDeleteAd(null);
        dispatch(
          setAlert({
            type: "success",
            message: "AD",
            description: "Ad deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        adId: deleteAd?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "campaigns/api/ads/websearch/",
      (res) => {
        setAds(res.data.ads);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [listRequest]);

  if (listLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteAd}
        title="Delete Ad"
        onCancel={() => setDeleteAd(null)}
        okText="Delete"
        confirmLoading={deleteLoading}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete ad '{deleteAd?.ad_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <FaBuysellads className="mr-2" /> Ads
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/home/ads/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={ads} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
