import {
  Avatar,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { MdEdit, MdSave } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import countries from "../../../data/countries.json";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { caxios } from "../../../lib/constants";
import {
  IContact,
  IContactEmail,
  IEntity,
  MEmailType,
  MGender,
} from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  entity: IEntity | null;
  emails: { id?: number; email: string; type: string }[];
  emailChanges: { op: "A" | "R"; id?: number; email?: string; type?: string }[];
  cob: { country: string; isocode: string } | null;
};

export default function ContactEdit() {
  useTitle("Edit Contact");

  const [contact, setContact] = useState<IContact>();
  const [emailInput, setEmailInput] = useState({ email: "", type: "1" });
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [entitiesLoading, setEntitiesLoading] = useState(true);
  const [info, setInfo] = useState<TInfo>({
    entity: null,
    emails: [],
    emailChanges: [],
    cob: null,
  });

  const params = useParams();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { user },
  } = useCTX();
  const [request, loading] = useRequest();
  const [detailRequest, detailLoading] = useRequest(true);

  const onSave = (values: any) => {
    const _info = {
      ...values,
      emailChanges: JSON.stringify(info.emailChanges),
      token: localStorage.getItem("token"),
      contactId: contact?.id,
    };

    if (values.dtob) {
      _info.dtob = values.dtob.format("YYYY-MM-DD");
    }

    if (info.cob) {
      _info.cob = info.cob.isocode;
    }

    if (info.entity) {
      _info.entityId = info.entity.id;
    }

    request(
      "post",
      "mailgroups/api/contacts/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "CONTACT",
            description: "Contact updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "mailgroups/api/contacts/webdetail/",
      (res) => {
        const c = res.data.contact;
        setContact(c);
        setInfo((old) => ({
          ...old,
          emails: c.emails.map((e: IContactEmail) => ({
            ...e,
            type: e.email_type,
          })),
          entity: c.entity,
        }));
      },
      {
        token: localStorage.getItem("token"),
        contact_id: params.id,
      }
    );
  }, [params.id, detailRequest]);

  useEffect(() => {
    setEntitiesLoading(true);
    caxios
      .post("entities/api/list/", {
        email: user?.email,
        pageRequested: 1,
      })
      .then((res) => {
        setEntities(res.data.entity_list);
      })
      .finally(() => {
        setEntitiesLoading(false);
      });
  }, [user?.email, dispatch]);

  useEffect(() => {
    if (!detailLoading && !entitiesLoading && !contact) {
      navigate(-1);
    }
  }, [detailLoading, entitiesLoading, contact, navigate]);

  if (detailLoading || entitiesLoading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  if (!contact) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Contact
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            name: contact.name,
            nick_name: contact.nick_name,
            prefix_name: contact.prefix_name,
            first_name: contact.first_name,
            middle_name: contact.middle_name,
            last_name: contact.last_name,
            sufix_name: contact.sufix_name,
            fonetic_name: contact.fonetic_name,
            dtob: contact.dtob ? dayjs(contact.dtob) : undefined,
            gender: contact.gender,
            job_title: contact.job_title,
            job_dept: contact.job_dept,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input contact name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item label="Nick Name" name="nick_name">
            <Input />
          </Form.Item>
          <Form.Item label="Prefix Name" name="prefix_name">
            <Input />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              { required: true, message: "Please input contact first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Middle Name" name="middle_name">
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              { required: true, message: "Please input contact last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Sufix Name" name="sufix_name">
            <Input />
          </Form.Item>
          <Form.Item label="Fonetic Name" name="fonetic_name">
            <Input />
          </Form.Item>
          <Form.Item label="Date Of Birth" name="dtob">
            <DatePicker
              disabledDate={(current) =>
                current && current > dayjs().subtract(18, "year")
              }
            />
          </Form.Item>
          <Form.Item label="Country Of Birth" name="cob">
            <Select
              showSearch
              placeholder="Country"
              optionFilterProp="label"
              defaultValue={contact.cob}
              onChange={(_, o) =>
                setInfo({
                  ...info,
                  cob: { country: (o as any).label, isocode: (o as any).value },
                })
              }
              options={countries}
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[
              { required: true, message: "Please input contact gender!" },
            ]}
          >
            <Radio.Group buttonStyle="solid">
              {MGender.keys.map((v) => (
                <Radio.Button key={v} value={MGender.getValue(v)}>
                  {v}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Job Title"
            name="job_title"
            rules={[
              { required: true, message: "Please input contact job title!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Job Department"
            name="job_dept"
            rules={[
              {
                required: true,
                message: "Please input contact job department!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Entity" name="entity">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: entities.map((e) => ({
                  key: e.name,
                  label: e.name,
                  icon: (
                    <Avatar
                      src={e?.photo ? e.photo : "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, entity: e }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.entity ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.entity?.photo
                          ? info.entity.photo
                          : "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.entity.name}</span>
                  </div>
                ) : (
                  "Select the Entity"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Emails"
            name="emails"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.emails.length === 0) {
                    return Promise.reject("Please add atleast one email!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div className="flex">
              <Input
                className="w-1/2 mr-4"
                value={emailInput.email}
                onChange={(e) =>
                  setEmailInput({ ...emailInput, email: e.target.value })
                }
                inputMode="email"
              />
              <Dropdown
                trigger={["click"]}
                className="mb-2"
                menu={{
                  items: MEmailType.keys.map((v) => ({
                    key: v,
                    label: v,
                    onClick: () =>
                      setEmailInput({
                        ...emailInput,
                        type: MEmailType.getValue(v),
                      }),
                  })),
                }}
              >
                <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                  {emailInput.type
                    ? MEmailType.getKey(emailInput.type)
                    : "Select type of email"}
                  <BiChevronDown className="ml-2" />
                </div>
              </Dropdown>
              <Button
                className="ml-16"
                icon={<FaPlus className="cursor-pointer text-gray-500" />}
                onClick={() => {
                  if (
                    emailInput.email.trim().length > 0 &&
                    info.emails.findIndex(
                      (e) => e.email === emailInput.email
                    ) === -1
                  ) {
                    setInfo((old) => ({
                      ...old,
                      emails: [...old.emails, emailInput],
                      emailChanges: [
                        ...old.emailChanges,
                        { op: "A", ...emailInput },
                      ],
                    }));
                    setEmailInput({ email: "", type: "1" });
                  }
                }}
              />
            </div>
            <div className="mt-2 flex flex-col">
              {info.emails.map((email) => (
                <div className="flex items-center m-2">
                  <Tag>{MEmailType.getKey(email.type)}</Tag>
                  <span className="ml-1 mr-8 text-xs">{email.email}</span>
                  <Button
                    size="small"
                    icon={<RxCross2 />}
                    onClick={() => {
                      const _info = {
                        ...info,
                        emails: info.emails.filter(
                          (e) => e.email !== email.email
                        ),
                      };

                      const eidx = _info.emailChanges.findIndex(
                        (c) => c.email === email.email && c.op === "A"
                      );
                      if (eidx !== -1) {
                        _info.emailChanges.splice(eidx, 1);
                      } else {
                        _info.emailChanges.push({ op: "R", id: email.id });
                      }

                      setInfo(_info);
                    }}
                  />
                </div>
              ))}
            </div>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
