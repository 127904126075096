import {
  Avatar,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IBrand, IEmailGroup, MCampaignTarget } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  target: string;
  brand: IBrand | null;
  email_groups: IEmailGroup[];
  start_dt: Dayjs;
};

export default function CampaignNew() {
  useTitle("New Campaign");

  const [info, setInfo] = useState<TInfo>({
    target: "1",
    brand: null,
    email_groups: [],
    start_dt: dayjs().add(1, "day"),
  });
  const [query, setQuery] = useState("");
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [emailGroups, setEmailGroups] = useState<IEmailGroup[]>([]);

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const [request, loading] = useRequest();
  const [brandListRequest, brandListLoading] = useRequest(true);
  const [emailGroupListRequest, emailGroupListLoading] = useRequest();

  const timeoutRef = useRef<NodeJS.Timeout>();

  const onCreate = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("campaign_name", values.campaign_name);
    _info.append("start_dt", values.start_dt.format("YYYY-MM-DD"));
    _info.append("end_dt", values.end_dt.format("YYYY-MM-DD"));
    _info.append("target", info.target);
    _info.append("brandId", `${info.brand?.id}`);
    _info.append(
      "emailGroups",
      JSON.stringify(info.email_groups.map((eg) => eg.id))
    );

    request(
      "post",
      "campaigns/api/campaigns/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "CAMPAIGN",
            description: "Campaign created successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    brandListRequest(
      "post",
      "campaigns/api/brands/websearch/",
      (res) => {
        setBrands(res.data.brands);
      },
      {
        token: localStorage.getItem("token"),
        pageRequested: 1,
      }
    );
  }, [brandListRequest]);

  useEffect(() => {
    if (query.trim().length > 1) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        emailGroupListRequest(
          "post",
          "mailgroups/api/emailgroups/websearch/",
          (res) => {
            const emails = res.data.emailgroups;
            setEmailGroups(emails);
          },
          {
            token: localStorage.getItem("token"),
            search_string: query,
            pageRequested: 1,
          }
        );
      }, 2000);
    } else {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setEmailGroups([]);
    }
  }, [query, emailGroupListRequest]);

  if (brandListLoading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdCampaign className="mr-2" /> Campaign
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onCreate}>
          <Form.Item
            label="Name"
            name="campaign_name"
            rules={[{ required: true, message: "Please input campaign name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Target"
            name="target"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.target)
                    return Promise.reject("Please input campaign target!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MCampaignTarget.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      target: MCampaignTarget.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.target
                  ? MCampaignTarget.getKey(info.target)
                  : "Select type of target"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="start_dt"
            rules={[
              {
                required: true,
                message: "Please input campaign start date!",
              },
            ]}
          >
            <DatePicker
              onChange={(d) => setInfo({ ...info, start_dt: d })}
              disabledDate={(current) => current && current < dayjs()}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="end_dt"
            rules={[
              {
                required: true,
                message: "Please input campaign end date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) =>
                current &&
                (current < dayjs() || current < info.start_dt.add(1, "day"))
              }
            />
          </Form.Item>
          <Form.Item
            label="Brand"
            name="brand"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.brand) {
                    return Promise.reject("Please select a brand!");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: brands.map((b) => ({
                  key: b.brand_name,
                  label: b.brand_name,
                  icon: (
                    <Avatar
                      src={b?.photo_main || "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, brand: b }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.brand ? (
                  <div className="flex items-center">
                    <Avatar
                      src={info.brand?.photo_main || "/images/covers/empty.png"}
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.brand.brand_name}</span>
                  </div>
                ) : (
                  "Select the Brand"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Email Groups"
            name="email_groups"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.email_groups.length === 0) {
                    return Promise.reject(
                      "Please add atleast one email group!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div className="relative">
              <Input.Search
                value={query}
                className="w-full"
                onChange={(e) => setQuery(e.target.value)}
                loading={emailGroupListLoading}
              />
              {emailGroupListLoading || emailGroups.length > 0 ? (
                <div className="bg-gray-50 max-h-96 w-full absolute z-50">
                  {emailGroupListLoading ? (
                    <div className="flex justify-center py-4">
                      <Spin size="small" />
                    </div>
                  ) : (
                    emailGroups.map((eg) => (
                      <div
                        className="flex items-center p-3 cursor-pointer hover:bg-white"
                        onClick={() => {
                          if (
                            info.email_groups.findIndex(
                              (_eg) => _eg.id === eg.id
                            ) === -1
                          ) {
                            setInfo((old) => ({
                              ...old,
                              email_groups: [...info.email_groups, eg],
                            }));
                            setQuery("");
                          }
                        }}
                      >
                        <span className="ml-1 text-xs">{eg.name}</span>
                      </div>
                    ))
                  )}
                </div>
              ) : null}
            </div>
            <div className="mt-2 flex flex-col">
              {info.email_groups.map((eg) => (
                <div className="flex items-center m-2">
                  <span className="ml-1 mr-8 text-xs">{eg.name}</span>
                  <Button
                    size="small"
                    icon={<RxCross2 />}
                    onClick={() =>
                      setInfo((old) => ({
                        ...old,
                        email_groups: old.email_groups.filter(
                          (e) => e.id !== eg.id
                        ),
                      }))
                    }
                  />
                </div>
              ))}
            </div>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
